import React from "react";

import * as S from "./styled";
import menuItems from "./content";
import CatSocialLinks from "../CatSocialLinks";
import CatBorderedTitle from "../CatBorderedTitle";
import { useStaticQuery, graphql } from "gatsby";

const CatFooter = () => {
  const newsletterSubmit = () => {
    console.log("working");
  };

  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "CatFooter/logo-horizontal.svg" }) { publicURL }
      }
    `
  );
  return (
    <div>
      <S.CatFooter>
        <S.TitleWrapper>
          <CatBorderedTitle
            align="center"            
            title={"Assine nossa newsletter"}
          />
          <S.Subtitle htmlFor="email-newsletter">
            Receba as novidades e conteúdos relacionados a tecnologia e também sobre nossas oportunidades
          </S.Subtitle>
        </S.TitleWrapper>
        
        
        <S.InputWrapper name="newsletter-form" action="/index" method="POST" data-netlify="true" className="field has-addons">
        
        
          <p className="control">
          
          <S.Input
           className="input is-rounded"
            id="email-newsletter"
            type="email"
            name="email"
            required
            placeholder="Insira aqui o seu melhor e-mail"
            
          />
        <input type="hidden" name="form-name" value="newsletter-form"className="input"  ></input>
          
          </p>
          <p className="control">
          <S.Button className="button is-primary is-rounded" type="submit">
            Assine
          </S.Button>
          </p>
        </S.InputWrapper>
       
        <S.MenuItems>
          {menuItems.map((menuItem, i) => (
            <S.MenuItemLink key={i} to={menuItem.url}>
              {menuItem.label}
            </S.MenuItemLink>
          ))}
        </S.MenuItems>
      </S.CatFooter>
      <S.Lastbar>
        <div className="navbar-brand primary">
          <S.LogoWrapper to="/">
            <img src={logo.publicURL} alt="CAT - People Insights" />
          </S.LogoWrapper>
        </div>
        <p>People Insights @ 2022 - Todos os Direitos Reservados</p>
        <CatSocialLinks />
      </S.Lastbar>
      <S.FooterRectangle />      
    </div>
  );
};

export default CatFooter;
