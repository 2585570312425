import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

import * as S from "./styled";
import menuItems from "./content";

const CatHeader = () => {
  const scrollStyle = {
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
  };
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const [isActive, setisActive] = React.useState(false);
  const { logo, chooseLanguage, flagBr, flagUs, flagPt } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "CatHeader/logo-horizontal-full.svg" }) { publicURL }
        chooseLanguage: file(relativePath: { eq: "CatHeader/choose-language.svg" }) { publicURL }
        flagBr: file(relativePath: { eq: "CatHeader/flag-br.png" }) { publicURL }
        flagUs: file(relativePath: { eq: "CatHeader/flag-us.png" }) { publicURL }
        flagPt: file(relativePath: { eq: "CatHeader/flag-pt.png" }) { publicURL }
      }
    `
  );  

  return (
    // <div className="is-fixed-top">
    //   <S.HeaderRectangle>
    //     &nbsp;          
    //   </S.HeaderRectangle>
    <>
      <S.MenuWrapper className="navbar is-fixed-top" role="navigation" aria-label="main navigation" style={scroll ? scrollStyle : null}>
        <div className="navbar-brand primary">
          <S.LogoWrapper to="/">
            <img src={logo.publicURL} alt="CAT - People Insight" />
          </S.LogoWrapper>          
          <span
            onKeyDown={() => {
              setisActive(!isActive);
            }}
            onClick={() => {
              setisActive(!isActive);
            }}
            tabIndex={0}
            role="button"
            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </span>
        </div>
        <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
          <div className="navbar-end">
            {menuItems.map((menuItem, i) =>
              menuItem.children ? (
                <div key={i} className="navbar-item has-dropdown is-hoverable">
                  <S.MenuLinksLink activeClassName="active">
                    <div className="navbar-link">{menuItem.label}</div>
                  </S.MenuLinksLink>
                  <div className="navbar-dropdown">
                    {menuItem.children.map((children, index) => (
                      <S.MenuLinksLink
                        key={index}
                        activeClassName="active"
                        to={children.url}
                        target={children.target}
                      >
                        <div className="navbar-item">{children.label}</div>
                      </S.MenuLinksLink>
                    ))}
                  </div>
                </div>
              ) : menuItem.defaultLink ? (
                <S.MenuLinksDefaultlink
                  key={i}
                  className="navbar-item"
                  href={menuItem.url}
                  target={menuItem.target}
                >
                  <div className="navbar-item">{menuItem.label}</div>
                </S.MenuLinksDefaultlink>
              ) : (
                <S.MenuLinksLink
                  key={i}
                  activeClassName="active"
                  className="navbar-item"
                  to={menuItem.url}
                  target={menuItem.target}
                  state={{
                    modal: menuItem.modal,
                  }}
                >
                  <div className="navbar-item">{menuItem.label}</div>
                </S.MenuLinksLink>
              )
            )}
          </div>
        </div>
      </S.MenuWrapper>      
      {/* <S.LanguageBar className="LanguageBar-Base">
        <div className="LanguageBar-Content">          
            <img className="LanguageBar-ChooseLanguage" src={ chooseLanguage.publicURL } alt="Escolha um idioma" />
            <div className="LanguageBar-ContentFlags">
              <img className="LanguageBar-Flag" src={ flagBr.publicURL } alt="Portugês Brasileiro" />
              <img className="LanguageBar-Flag" src={ flagUs.publicURL } alt="Inglês Americano" />
              <img className="LanguageBar-Flag" src={ flagPt.publicURL } alt="Portugês Europeu" />
            </div>
        </div>
      </S.LanguageBar> */}
      </>
    // </div>
  );
};

export default CatHeader;