import styled from "styled-components";

export const TitleSection = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
`;

export const TitleWrapper = styled.div`
  border-bottom: 0 solid var(--esx-red);
  border-radius: 5px;
  margin-bottom: 2rem;
  width: fit-content;

  h1 {
    display: inline;
  }

  .title-icon {
    width: 15px;
    height: 15px;
    margin: auto 40px;
    vertical-align: 15%;
    display: inline;
  }
`;
export const Title = styled.h1`
  font-family: Exo2;
  font-weight: bold;
  color: #ffffff;
  font-size: var(--medium-title-size);
  font-weight: bold;
  font-size:80px;
`;