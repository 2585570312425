import styled from "styled-components";
import { Link } from "gatsby";
import media from "styled-media-query";

export const FooterRectangle = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  height: 10px;
  width: 100%;
  background-color: var(--cat-header-retangle) !important;
`;

export const CatFooter = styled.footer`
  background-color: #2A2944;
  background-image: var(--cat-footer-background-image);  
  background-repeat: no-repeat;
  background-size: 14.5%;
  background-position-x: 80%;
  padding: 3rem var(--default-padding-desktop);
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
  `}
  @media screen and (min-width: 100px) and (max-width: 1173px){
    width: 1300px;  
  }
`;
export const TitleWrapper = styled.div``;
export const Subtitle = styled.label`
  font-family: Exo2;
  color: #A0A0D7;
  font-size:25px;
`;
export const InputWrapper = styled.form`
  padding-top: 2rem;
  display: flex;
  max-width: 40rem;  
  

`;
export const Input = styled.input`
width:500px;

  ${media.lessThan("small")`
    margin-bottom: 1rem;
    
  `}
`;
export const Button = styled.button`
  width: 10rem;
  font-family: Exo2;
  font-weight: bold;
  background-color: #8630F2 !important;
  ${media.lessThan("small")`
    width: 100%;
  `}
`;
export const MenuItems = styled.nav`
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  ${media.lessThan("medium")`
    flex-wrap: wrap;
  `};
`;
export const MenuItemLink = styled(Link)`
  padding: 0 20px;
  font-family: Exo2;
  color: #A0A0D7;
  text-decoration: none;
  transition: color 0.5s;
  ${media.lessThan("medium")`
  `};
  &:hover {
    color: #fafafa;
    opacity: 1;
  }
`;
export const Lastbar = styled.div`
  background-color: #1B1B2C;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 1rem var(--default-padding-desktop);
  padding-left: 16%;
  padding-right: 16%;
  @media screen and (min-width: 100px) and (max-width: 1300px){
    width: 1300px;  
  }
  ${media.lessThan("medium")`
    flex-direction: column;
    height: 15rem;
    padding: var(--default-padding-mobile);
  `}
  ${media.greaterThan("2000px")`
  padding-left: 23%;
  padding-right: 23%;
`}
${media.greaterThan("2300px")`
padding-left: 26.5%;
padding-right: 26.5%;

`}
${media.greaterThan("2600px")`
padding-left: 30%;
padding-right: 30%;

`}
${media.greaterThan("3000px")`
padding-left: 33.5%;
padding-right: 33.5%;

`}
${media.greaterThan("3300px")`
padding-left: 37.2%;
padding-right: 37.2%;

`}
${media.greaterThan("3600px")`
padding-left: 41%;
padding-right: 41%;

`}
${media.greaterThan("4000px")`
padding-left: 44.5%;
padding-right: 44.5%;

`}
`;

export const LogoWrapper = styled(Link)`
  width: 6rem !important;
  margin: auto 0 auto 1rem !important;
`;

export const BorderWrapper = styled.span`
  height: 0.5rem;
  width: 100%;
  background-image: var(--light-gradient);
  display: flex;
  justify-content: center;
`;

export const Border = styled.span`
  width: 80%;
  height: 0.5rem;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  background-color: var(--esx-red);
  position: absolute;
`;
