import styled from "styled-components";
import { Link } from "gatsby";
import media from "styled-media-query";

export const LanguageBar = styled.div`
  margin-top: 82px;
  background-color: #fafafa !important;  

  .LanguageBar-Base {
    margin: 0;
    padding: 0;
    border: 0;    
    width: 100%;
  }

  .LanguageBar-Content {    
    margin-top: auto;
    margin-left: auto;
    margin-right: 0;
    height: 24px;
    width: 300px;   
  }

  .LanguageBar-ChooseLanguage {
    margin: 4px 0;
    width: 188px;
    height: 16px;
    display: inline;
  }

  .LanguageBar-ContentFlags {
    margin: auto 0;
    width: 72px;
    height: 100%;
    display: inline-block;
  }

  .LanguageBar-Flag {
    margin: auto 0;    
    display: inline;
    width: 24px;
    height: 24px;
  }
`;

export const MenuWrapper = styled.nav`
  // margin-top: 10px;
  border-top: 10px solid var(--cat-primary);
  padding: 0.5rem var(--default-padding-desktop);
  background-color: #fafafa !important;
  padding-left: 16%;
  padding-right: 16%;
  
  ${media.greaterThan("2000px")`
  padding-left: 23%;
  padding-right: 23%;
`}
${media.greaterThan("2300px")`
padding-left: 26.5%;
padding-right: 26.5%;

`}
${media.greaterThan("2600px")`
padding-left: 30%;
padding-right: 30%;

`}
${media.greaterThan("3000px")`
padding-left: 33.5%;
padding-right: 33.5%;

`}
${media.greaterThan("3300px")`
padding-left: 37.2%;
padding-right: 37.2%;

`}
${media.greaterThan("3600px")`
padding-left: 41%;
padding-right: 41%;

`}
${media.greaterThan("4000px")`
padding-left: 44.5%;
padding-right: 44.5%;

`}
  .navbar-dropdown {
    border-bottom: 0.5rem solid var(--esx-red);
  }
  .navbar-brand {
    display: flex;
    align-items: center;
    p {
      padding-left: 0.5rem;
      font-size: 0.9rem;
      color: var(--esx-black);
    }
  }
  ${media.lessThan("small")`
  .navbar-brand {
    display: flex;
    align-items: center;
    p {
      display: none;
    }
  }
  padding: 0.5rem var(--default-padding-mobile);
      .navbar-dropdown {
      border-bottom: none;
    }
  `}
`;
export const LogoWrapper = styled(Link)`
  width: 12rem !important;
  margin: auto 0 !important;
`;

export const MenuLinksLink = styled(Link)`
  margin: auto;
  text-decoration: none;
  transition: color 0.5s;
  &.active {
    .navbar-item {
      color: var(--cat-primary);
      font-weight: bold;
    }
  }
`;
export const MenuLinksDefaultlink = styled.a`
  margin: auto;
  text-decoration: none;
  transition: color 0.5s;
  &.active {
    .navbar-item {
      color: var(--cat-primary);
      font-weight: bold;
    }
  }
`;
