import { FacebookSquare as Facebook } from "styled-icons/boxicons-logos/FacebookSquare";
import { Instagram } from "styled-icons/boxicons-logos/Instagram";
import { LinkedinSquare as LinkedIn } from "styled-icons/boxicons-logos/LinkedinSquare";
import { PhoneCall as Telefone } from "styled-icons/boxicons-regular/PhoneCall";
const Icons = {
  Facebook,
  LinkedIn,
  Instagram,
  Telefone,
};

export default Icons;
