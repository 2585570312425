const menuItems = [
  {
    label: "Sobre",
    url: "/",
  },
  {
    label: "Soluções",
    url: "/solucoes",    
  },
  // {
  //   label: "Vagas",
  //   url: "/vagas",    
  // },
  // {
  //   label: "Cases",
  //   url: "/cases",
  // },
  // {
  //   label: "Blog",
  //   url: "/blog",
  // },
  {
    label: "Contato",
    url: "/contato",
  },
];

export default menuItems;
