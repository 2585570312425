const menuItems = [
  {
    label: "Sobre",
    url: "/",
    target: "_self"
  },
  {
    label: "Soluções",
    url: "/solucoes",
    target: "_self"
  },
  {
    label: "Vagas",
    url: "https://www.linkedin.com/company/cat-speech-analytics/jobs/",
    target: "_blank"
  },
  // {
  //   label: "Cases",
  //   url: "/cases",
  // },
  {
    label: "Blog",
    url: "https://www.linkedin.com/company/cat-speech-analytics/posts/",
    target: "_blank"
  },
  {
    label: "Contato",
    url: "/contato",
    target: "_self"
  },
];

export default menuItems;
