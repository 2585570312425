const links = [
  {
    label: "LinkedIn",
    url: `https://www.linkedin.com/company/cat-people-analytics/`,
  },
  {
    label: "Facebook",
    url: `https://www.facebook.com/catanalytics`,
  },
  {
    label: "Instagram",
    url: `https://www.instagram.com/catanalytics/`,
  },
];

export default links;
