import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as S from "./styled";

import PropTypes from "prop-types";

const CatBorderedTitle = ({ title, color, borderColor, align, dense }) => {
  const { titleIcon } = useStaticQuery(
    graphql`
      query {
        titleIcon: file(relativePath: { eq: "CatCommon/rectangle.svg" }) { publicURL }
      }
    `
  );

  return (
    <S.TitleSection style={{ justifyContent: align }}>
      <S.TitleWrapper
        style={
          dense
            ? { margin: 0, borderBottom: `0.5rem solid ${borderColor}` }
            : { borderBottom: `0.5rem solid ${borderColor}` }
        }
      >
        <img src={titleIcon.publicURL} className="title-icon" alt="Retângulo Roxo" />
        <S.Title style={{ color: color }}>{title || "Insert title"}</S.Title>
        <img src={titleIcon.publicURL} className="title-icon" alt="Retângulo Roxo" />
      </S.TitleWrapper>
    </S.TitleSection>
  );
};

CatBorderedTitle.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  align: PropTypes.string,
  dense: PropTypes.bool,
};

export default CatBorderedTitle;
